// this is a special stylesheet which ends up including ONLY styles
// meant to target devices at a width of 1100px. IE8- does not support
// media queries so this ends up being built and served to them.
// from http://jakearchibald.github.io/sass-ie/
$lock-media-queries-to: 1100px;
@import 'main';

.logo img {
  width: auto !important;
  height: auto !important;
}
